type ApiResponse = { Result: string; Type: string; Url: string }[] | null;

export const useAutocomplete = (apiUrl: string = "api/partautocomplete") => {
    const api = useApi();
    let controller: AbortController | null = null;

    return async function autocomplete(
        query: string,
        parameters?: Record<string, string | number | undefined>
    ): Promise<AutocompleteItem[]> {
        if (controller) controller.abort(); // abort in-progress request, if any

        controller = new AbortController();

        try {
            const response = await api<ApiResponse>(apiUrl, {
                method: "GET",
                query: { query, ...parameters },
                signal: controller.signal,
                onResponse() {
                    controller = null;
                },
            });
            return Array.isArray(response)
                ? response.map((item) => ({
                      label: item.Result,
                      category: item.Type,
                      url: item.Url,
                  }))
                : [];
        } catch (_err) {
            // aborted or server issue, no need to bother the UI
        }

        return [];
    };
};
