<template>
    <form class="input-group input-group-with-button h-12 w-full" @submit.prevent="searchNavigate(searchTerm)">
        <input
            id="footerSearchText"
            v-model="searchTerm"
            type="text"
            class="flex-grow border-white focus:border-white focus:ring-transparent text-sm"
            :aria-label="$t('Layout.SearchButtonTitle')"
            maxlength="100"
            :placeholder="$t('Layout.SearchPartNumberDefaultText')" />
        <button
            id="searchSubmitButtonFooter"
            class="input-group-after btn ring-white focus:ring-white"
            :title="$t('Layout.SearchButtonTitle')">
            <span class="fas fa-magnifying-glass text-gray-300" aria-hidden="true"></span>
        </button>
    </form>
</template>
<script setup lang="ts">
const globalConfig = useStateGlobalConfig();
const searchTerm = ref(globalConfig.value.SearchText);
const searchNavigate = useSearchNavigate();
</script>
